import React, { useState, useEffect, useContext } from 'react';
import {
    Button, TextField, MenuItem, FormControl, FormControlLabel,
    Checkbox, Grid, Box
} from '@mui/material';
import Select, { SelectChangeEvent } from "@mui/material/Select"
import { useLocation } from 'react-router-dom';
// import { CheckboxItem } from '../CheckboxItem';
import {
    fetchRetailElectricProviders,
    newAgreement
} from '../../fetch/fetch';
import type { Provider, SmtGetInfoProps, MyAddressContextProps } from '../../types'
import { FormLoading } from '../FormLoading';
import { MyAddressContext } from '../../context-manager';
import { withSnackbar } from '../SnackbarHOC';

const SmtGetInfo: React.FC<SmtGetInfoProps> = ({ handleShowAgreement, handleBack, hideBack, snackbarShowMessage }) => {
    const location = useLocation();
    const [ providers, setProviders ] = useState<Provider[]>([]);
    const [ loadingProviders, setLoadingProviders ] = useState(false);
    const [ validated, setValidated ] = useState(false);
    const [ selectedProvider, setSelectedProvider ] = useState<string>('');
    const [ showProviderError, setShowProviderError ] = useState(false);
    const [ showConsentError, setShowConsentError ] = useState(false);
    const [ meterNumber, setMeterNumber ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ submitting, setSubmitting ] = useState(false);
    const [ isAgree, setIsAgree ] = useState(false);
    const [ forwardEmail, setForwardEmail ] = useState(false);
    const { myAddress, utilityCode } = useContext(MyAddressContext) as MyAddressContextProps;
    
    useEffect(() => {
        setLoadingProviders(true)
        fetchRetailElectricProviders().then(res => {
            if (res) setProviders(res)
            setLoadingProviders(false)
        })
    }, [])

    const handleSubmit = (evt: React.FormEvent) => {
        evt.preventDefault();
        evt.stopPropagation();

        const esiid = myAddress?.esiid;
        const form = evt.currentTarget as HTMLInputElement;
        const validProvider = handleValidProvider()
        setValidated(true);
        if (!isAgree) {
            setShowConsentError(true);
            return;
        }
        if (!form.checkValidity() || !validProvider || !selectedProvider || !esiid || submitting) return;

        setSubmitting(true);

        const { certificate_number } = providers.filter((val) => val.business_name === selectedProvider)[0];

        newAgreement(
            esiid,
            meterNumber,
            certificate_number,
            email,
            forwardEmail ? '1' : '0'
        ).then(res => {
            if (res && res.response && res.response.consent_id) {
                handleShowAgreement(res.response.consent_id, email);
            }
            else {
                if (res.error_code && res.error_code === '500') {
                    snackbarShowMessage?.('Incorrect ESIID-METER-REP Combination', 'error');
                    // console.error('Incorrect ESIID-METER-REP Combination');
                }
                setSubmitting(false);
                console.error('error: new agreement');
            }
        })
    }

    const handleValidProvider = () => {
        let isValid = true
        setShowProviderError(false)
        if (!selectedProvider){
            isValid = false
            setShowProviderError(true)
        }
        return isValid
    }

    const Placeholder = ({ children }: { children: string }) => <div style={{color:'#666'}}>{children}</div>

    return (
        <Box position="relative">
            <h2>We are almost there!</h2>
            <p>To ensure the accuracy of the data, we will need your Smart Meter Texas (SMT) number and the permission to access the data</p>
            <form onSubmit={handleSubmit}>
                <FormControl fullWidth margin="normal">
                    <Select
                        labelId="rep-select-label"
                        id="rep-select"
                        value={selectedProvider}
                        onChange={(evt: SelectChangeEvent) => {
                            setSelectedProvider(evt.target.value)
                            setShowProviderError(false)
                        }}
                        disabled={submitting}
                        displayEmpty={true}
                        renderValue={selectedProvider !== '' ? undefined : () => <Placeholder>Select...</Placeholder>}
                    >
                        {
                            providers.map((val, idx) => <MenuItem value={val.business_name} key={idx}>{val.business_name}</MenuItem>)
                        }
                    </Select>
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <TextField
                        id="meter-number"
                        name="meter-number"
                        label="Meter Number"
                        type="string"
                        value={meterNumber}
                        onChange={(evt) => setMeterNumber(evt.target.value)}
                        disabled={submitting}
                    />
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <TextField
                        id="email"
                        name="email"
                        label="Email"
                        type="email"
                        value={email}
                        onChange={(evt) => setEmail(evt.target.value)}
                        disabled={submitting}
                    />
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <FormControlLabel
                        control={<Checkbox checked={forwardEmail}
                        onClick={() => {
                            setForwardEmail(!forwardEmail)
                        }} />}
                        label={
                            <span>
                                Would you like to receive a copy of the consent?
                            </span>
                        }
                        disabled={submitting}
                    />
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <FormControlLabel
                        control={<Checkbox checked={isAgree}
                        onClick={() => {
                            setShowConsentError(false);
                            setIsAgree(!isAgree)
                        }} />}
                        label={
                            <span>
                                By clicking on the box, I agree to grant Tomorrow Energy permission to access my account on Smart Meter Texas (SMT). In addition, Tomorrow Energy shall provide all SMT email communications received by Tomorrow Energy on your behalf to your email address provided.
                            </span>
                        }
                        disabled={submitting}
                    />
                </FormControl>
                <Grid container sx={{mt:5}}>
                    {
                        !hideBack &&
                        <Button type="button" variant="outlined" disabled={submitting} sx={{mr:4}} onClick={() => { handleBack() }}>Back</Button>
                    }
                    <Button type="submit" variant="contained" disabled={submitting}>Continue</Button>
                </Grid>
            </form>
            <FormLoading show={submitting} />
        </Box>
    )
}

export default withSnackbar(SmtGetInfo)