export const REPs = [
    {
        providerId: '225',
        providerName: 'Tomorrow Energy',
        host: true
    },
    {
        providerId: '163',
        providerName: 'Direct Energy'
    },
    {
        providerId: '176',
        providerName: 'TXU Energy'
    },
    {
        providerId: '159',
        providerName: 'Reliant'
    },
    {
        providerId: '206',
        providerName: 'Green Mountain'
    },
    {
        providerId: '183',
        providerName: 'Champion Energy'
    }
];

export const API_KEY = '63a8c3a5fd35831eef37918d1b0cd0cf';
export const API_URL = 'https://bb0xg7o51l.execute-api.us-east-2.amazonaws.com/beta';
export const POWERLEGO_API_URL = 'https://www.powerlego.com/ApiGateway';
export const GOOGLE_API_KEY = 'AIzaSyCsreMGj8xStaCAs9V2HjMizheuJaVlII0';