import { StyledFooter, FooterNav } from './styles/common.styled';
import moment from 'moment';
import { Container } from '@mui/system';
import { Grid } from '@mui/material';

export const Footer = () => {
    return (
        <>
            <StyledFooter>
                <Container>
                    <FooterNav>
                        <Grid container justifyContent="space-between">
                            <Grid item xs={12} sm={6}>
                                    <a href="https://tomorrowenergy.com/legal/privacy-policy/">Privacy Policy</a>
                                    <a href="https://tomorrowenergy.com/legal/terms-and-conditions/">Terms and Conditions</a>
                                    <a href="https://tomorrowenergy.com/legal/compliance/">Compliance</a>
                            </Grid>
                            <Grid item xs={12} sm={6} textAlign={{xs:"start",sm:"end"}} marginTop={{xs:2,sm:0}}>
                                Copyright © {moment().format('YYYY')} Tomorrow Energy
                            </Grid>
                        </Grid>
                    </FooterNav>
                    <Grid container justifyContent="space-between">
                        <Grid item xs={12} sm={8}>
                            <h5>STATE LICENCES</h5>
                            <p>Electricity: IL: 11-0743 | MD: IR-3079 | NJ: ESL-0178 | OH: 12-503E (4) | PA: A-2011-2250633 | TX: PUCT License #: 10270</p>
                            <p>Gas: MD: IR-3151 | NJ: GSL-0130 | OH: 13-317G (3) | PA: A-2017-2609180</p>
                            <p>Information on generation energy sources, energy efficiency, environmental impacts or historical billing data is available upon request.</p>
                        </Grid>
                        <Grid item xs={12} sm={4} textAlign={{xs:"start",sm:"end"}}>
                            <img src="/images/EMC-Recognition-Tile.png" alt="" />
                        </Grid>
                    </Grid>
                </Container>
            </StyledFooter>
        </>
    )
}