import { useState } from "react";
import {
    StyledHeader, Logo, StyledHeaderInner,
    HeaderMenu, HeaderMenuItem, MenuToggle
} from "./styles/common.styled";
// import { Button } from '@mui/material';

export const Header = () => {
    const [ showMenu, setShowMenu ] = useState(false);

    return (
        <StyledHeader>
            <StyledHeaderInner>
                <Logo href="https://tomorrowenergy.com/">
                    <img src="https://tomorrowenergy.com/wp-content/uploads/2018/05/logo.svg" alt="" height="40" style={{marginRight:20}} />
                    <span>Side-by-side</span>
                </Logo>
                <MenuToggle
                    active={`${showMenu}`}
                    onClick={() => {
                        if (showMenu) {
                            document.body.style.overflow = 'unset'
                        }
                        else {
                            document.body.style.overflow = 'hidden'
                        }
                        setShowMenu(!showMenu)
                    }}
                />
                <HeaderMenu active={`${showMenu}`}>
                    <HeaderMenuItem href="https://tomorrowenergy.com/energy-plans/">ENERGY PLANS</HeaderMenuItem>
                    <HeaderMenuItem href="https://tomorrowenergy.com/about-us/management/">ABOUT US</HeaderMenuItem>
                    <HeaderMenuItem href="https://tomorrowenergy.esgglobal.net/customerportal/myeaccount/login?connectName=TOMORROWENERGY"><img src="/images/icon-my-account.svg" alt="" />My Account</HeaderMenuItem>
                    {/* <Button size="medium" variant="contained" onClick={() => {
                        window.location.href = "###"
                    }}>ENROLL</Button> */}
                </HeaderMenu>
            </StyledHeaderInner>
        </StyledHeader>
    )
}