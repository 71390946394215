import { ThemeProvider, createTheme } from '@mui/material/styles';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import Homepage from './homepage';
import Compare from './compare';
import { PageWrapper, PageMain } from './components/styles/common.styled';
import './App.css';

declare module '@mui/material/Button/Button' {
	interface ButtonPropsVariantOverrides {
		'contained-secondary': true;
	}
}

const globalTheme = createTheme({
	palette: {
		primary: {
			main: '#f76b2c',
			dark: '#f65a13'
		},
		secondary: {
			main: '#1e8093',
			dark: '#1e8093',
			light: '#1e8093'
		}
	}
})

const theme = createTheme({
	components: {
		MuiAutocomplete: {
			styleOverrides: {
				root: {
					backgroundColor: '#fff',
					borderRadius: 12
				}
			}
		},
		MuiTextField: {
			styleOverrides: {
				root: {
					backgroundColor: '#fff',
					borderRadius: 12,
					'& fieldset': {
						borderRadius: 12
					},
					'& input': {
						borderRadius: '12px !important'
					}
				}
			}
		},
		MuiSelect: {
			styleOverrides: {
				outlined: {
					backgroundColor: '#fff'
				}
			}
		},
		MuiButton: {
			styleOverrides: {
				root: {
					borderRadius: 30,
					fontWeight: 700,
					color: '#fff',
					boxShadow: 'none',
					textTransform: 'uppercase',
					'&:hover': {
						backgroundColor: '#f65a13',
						boxShadow: '0 2px 5px rgb(0 0 0 / 15%)',
					}
				},
				sizeLarge: {
					fontSize: 17,
					padding: '16px 40px'
				},
				sizeMedium: {
					padding: '12px 20px'
				}
			},
			variants: [{
				props: { variant: 'outlined' },
				style: {
					boxShadow: 'none',
					backgroundColor: '#fff',
					border: `2px solid ${globalTheme.palette.secondary.main}`,
					color: globalTheme.palette.secondary.main,
					'&:hover': {
						backgroundColor: '#fff',
						border: `2px solid ${globalTheme.palette.secondary.main}`,
						color: globalTheme.palette.secondary.main
					}
				}
			}, {
				props: { variant: 'contained-secondary' },
				style: {
					backgroundColor: globalTheme.palette.secondary.main,
					boxShadow: `0px 0px 0px 2px ${globalTheme.palette.secondary.main} inset`,
					color: '#fff',
					'&:hover': {
						backgroundColor: globalTheme.palette.secondary.main,
						boxShadow: `0px 0px 0px 2px ${globalTheme.palette.secondary.main} inset`,
						color: '#fff'
					}
				}
			}, {
				props: { variant: 'text' },
				style: {
					backgroundColor: 'transparent',
					boxShadow: 'none',
					padding: 0,
					'&:hover': {
						backgroundColor: 'transparent'
					}
				}
			}]
		}
	}
}, globalTheme);

function App() {
	return (
		<ThemeProvider theme={theme}>
			<PageWrapper>
				<PageMain>
					<Header />
					<BrowserRouter>
						<Routes>
							<Route path="/" element={<Homepage />} />
							<Route path="/compare" element={<Compare />} />
						</Routes>
					</BrowserRouter>
				</PageMain>
				<Footer />
			</PageWrapper>
		</ThemeProvider>
	);
}

export default App;
